.draftJsToolbar__buttonWrapper__1Dmqh {
  display: inline-block;
}

.draftJsToolbar__button__qi1gf {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsToolbar__button__qi1gf svg {
  fill: #888;
}

.draftJsToolbar__button__qi1gf:hover, .draftJsToolbar__button__qi1gf:focus {
  background: #f3f3f3;
  outline: 0; /* reset for :focus */
}

.draftJsToolbar__active__3qcpF {
  background: #efefef;
  color: #444;
}

.draftJsToolbar__active__3qcpF svg {
  fill: #444;
}
.draftJsToolbar__separator__3U7qt {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}
.draftJsToolbar__toolbar__dNtBH {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220,220,220,1);
  z-index: 2;
  box-sizing: border-box;
}

.draftJsToolbar__toolbar__dNtBH:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.draftJsToolbar__toolbar__dNtBH:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}

.draftJsEmojiPlugin__image__192TI {}

.draftJsMentionPlugin__input__1Wxng {
  height: 34px;
  width: 220px;
  padding: 0 12px;
  font-size: 15px;
  font-family: inherit;
  background-color: transparent;
  border: none;
  color: #444;
}

.draftJsMentionPlugin__input__1Wxng:focus {
  outline: none;
}

.draftJsMentionPlugin__input__1Wxng::-webkit-input-placeholder {
  color: #888;
}

.draftJsMentionPlugin__input__1Wxng::-ms-input-placeholder {
  color: #888;
}

.draftJsMentionPlugin__input__1Wxng::placeholder {
  color: #888;
}

.draftJsMentionPlugin__inputInvalid__X9hHv {
  color: #e65757;
}

.draftJsMentionPlugin__link__TQHAX {
  color: #2996da;
  text-decoration: underline;
}

.draftJsMentionPlugin__iframeContainer__21EVZ {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
}

.draftJsMentionPlugin__iframe__stjRT {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.draftJsMentionPlugin__invalidVideoSrc__3dIji {
  text-align: center;
  background-color: #eaeaea;
  padding: 1em;
}

.draftJsLinkifyPlugin__link__2ittM, .draftJsLinkifyPlugin__link__2ittM:visited {
  color: #5e93c5;
  text-decoration: none;
}

.draftJsLinkifyPlugin__link__2ittM:hover, .draftJsLinkifyPlugin__link__2ittM:focus {
  color: #7eadda;
  outline: 0; /* reset for :focus */
  cursor: pointer;
}

.draftJsLinkifyPlugin__link__2ittM:active {
  color: #4a7bab;
}

